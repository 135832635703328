import React from 'react'
import { motion } from "framer-motion"


const animVariants = {
    visible: {
        x: [0, -1, 1],
        transition: { delay: 0, yoyo: Infinity, duration: 1 }
    },
    hover: {
        scale: 1.3
    }
}


function Content2() {
    return (
        <div>
            <div className="card-wrapper">
                <div className="aboutme-content2-link">
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://gitlab.com/ErinDeji"
                        target="_blank"
                        rel="noreferrer">
                        <span class="iconify" data-icon="bx:bxl-gitlab" data-inline="false"></span>
                    </motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://github.com/erinoggz"
                        target="_blank"
                        rel="noreferrer">
                        <span class="iconify" data-icon="raphael:githubalt" data-inline="false"></span>
                    </motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://www.linkedin.com/in/erin-deji/" target="_blank" rel="noreferrer"><span class="iconify" data-icon="ant-design:linkedin-filled" data-inline="false"></span></motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://twitter.com/ande_oggz" target="_blank" rel="noreferrer"><span class="iconify" data-icon="uim:twitter" data-inline="false"></span></motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://www.instagram.com/ande_lifa/" target="_blank" rel="noreferrer"><span class="iconify" data-icon="ant-design:instagram-filled" data-inline="false"></span></motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://drive.google.com/file/d/1Q6As274gq7rfmpUCfnGw4L5uW54NEUNr/view?usp=sharing" target="_blank" rel="noreferrer"><span class="iconify" data-icon="ant-design:file-pdf-filled" data-inline="false"></span></motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="mailto:erin.deji@gmail.com?subject=Hello Erin" target="_blank" rel="noreferrer" ><span class="iconify" data-icon="mdi:gmail" data-inline="false"></span></motion.a>
                    <motion.a
                        whileHover={{
                            scale: 1.7,
                            textShadow: "0px 0px 8px #333333",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                        }}
                        href="https://tinyurl.com/dejierin" target="_blank" rel="noopener noreferrer"><span className="iconify" data-icon="ph:whatsapp-logo-fill" data-inline="false"></span></motion.a>
                </div>
                <div className="aboutme-content">
                    <div className="aboutme-content-title">
                        <div style={{ display: "flex" }}>
                            <div>Hi, i'm Erin</div>
                            <motion.div
                                variants={animVariants}
                                animate="visible"
                                whileHover="hover"
                            ><span>👋</span>
                            </motion.div>
                        </div>

                    </div>
                    I’m a software engineer based in London, United Kingdom 🇬🇧. I develop interesting and robust applications using frameworks and technologies like TypeScript, Node.js, Apollo GraphQL, Nest.js, Next.js, React.js, Mongo DB, Postgres SQL and MySQL.
                    <div>
                        I am an avid advocate for diversity in technology🤖.
                        <div>
                        I have more than 6 years of experience building and maintaining scalable applications. On the backend part, I also have solid experience building microservices with an ApiGateway (prefferably with Apollo GraphQL) for a single source to access all applications. I've maintained and developed features used by millions of users. I've developed software products and features for startups, mid and top companies with backend and frontend technologies.  </div>
                    </div>


                    <div>
                        Currently, I’m focused on moving to the next stage of my career as learning never ends.
                    </div>
                    <div>
                        Thanks for reading 😊
                    </div>



                </div>
                {/* <div className="dev-icon-box">
                    <span className="Devicon iconify" data-icon="fluent:window-dev-tools-24-filled" data-inline="false"></span>
                </div> */}
            </div>
        </div>
    )
}

export default Content2
